import {ApiService} from './networkManager.js'

export const getMe = async () => {
    const path = '/staff/me'
    const res = await ApiService.get(path)
    return res['data']
}

export const getUser = async () => {
	const path = "/user";
	const res = await ApiService.get(path);
	return res["data"];
};

export const login = async (email: string, password: string) => {
    const path = '/staff/login'
    const res = await ApiService.post(path, {email, password})
    return res['data']
}

export const forgotPassoword = async () => {

}

export const getOrgCustomAnalytics = async (id: number | string) => {
    const path = '/staff/custom_analytics/' + id;
    const res = await ApiService.get(path);
    return res['data'];
};

export const getOrgSurveys = async (id: number | string) => {
    const path = '/staff/surveys/' + id;
    const res = await ApiService.get(path);
    return res['data'];
  };

export const getOrgCourses = async (id: number | string) => {
    const path = '/staff/courses/' + id;
    const res = await ApiService.get(path);
    return res['data'];
};

export const editCourse = async ( id: number, data: any ) => {
    const path = '/staff/course/' + id;
    const res = await ApiService.patch(path, data)
    return res['data']
}

export const editSurvey = async ( id: number, data: any ) => {
    const path = '/staff/surveys/' + id;
    const res = await ApiService.patch(path, data)
    return res['data']
}

export const editCustomAnalytic = async ( id: number, data: any ) => {
    const path = '/staff/custom_analytics/' + id;
    const res = await ApiService.patch(path, data)
    return res['data']
}

export const addCustomAnalytic = async (id: number, data: any) => {
    const path = '/staff/custom_analytics/' + id;
    const res = await ApiService.post(path, data);
    return res['success'];
};

export const addSurvey = async (data: any) => {
    const path = '/staff/survey';
    const res = await ApiService.post(path, data);
    return res['success'];
};

export const addGoalPeriod = async (data: any) => {
    const path = "/staff/goal_period"
    return ApiService.post(path, data)
}

export const getGoalPeriods = async () => {
    const path = '/staff/goal_period';
    const res = await ApiService.get(path)
    return res['data']
}

export const activateGoalPeriod = async (id: number | string) => {
    const path = `/staff/activate_period/${id}`
    return ApiService.post(path)
}

export const activateSurvey = async (id: any) => {
    const path = `/staff/survey/${id}/activate`;
    const res = await ApiService.patch(path);
    return res["success"];
};

export const activateMultiResponseSurvey = async (id: any) => {
    const path = `/staff/survey/${id}/multi_response`;
    const res = await ApiService.patch(path);
    return res["success"];
};

export const activateCustomAnalytics = async (id: any) => {
    const path = `/staff/custom_analytics/${id}/activate`;
    const res = await ApiService.patch(path);
    return res["success"];
};

export const publicCustomAnalytics = async (id: any) => {
    const path = `/staff/custom_analytics/${id}/activate/public`;
    const res = await ApiService.patch(path);
    return res["success"];
};

export const createNewUser = async (data: any) => {
    const path = '/staff/user/create'
    const res = await ApiService.post(path, data)
    return res['success']
}

export const updateUser = async (data: any, id: number | string) => {
    const path = '/staff/user/'+ id
    const res = await ApiService.patch(path, data)
    return res['success']
}

export const updatePlan = async (data: any, id: number | string) => {
    const path = `/staff/org/${id}/plan`
    const res = await ApiService.patch(path, data)
    return res['success']
}

export const getOrgs = async () => {
    const path = '/staff/org'
    const res = await ApiService.get(path)
    return res['data']
}

export const getOrgUsers = async (id: number | string) => {
    const path = `/staff/org/${id}/users`
    const res = await ApiService.get(path)
    return res['data']
}

export const addOrg = async (data: any) => {
    const path = '/staff/org'
    const res = await ApiService.post(path, data)
    return res['success']
}

export const editOrg = async (data: any) => {
    const path = '/staff/org'
    const res = await ApiService.patch(path, data)
    return res['success']
}

const uploadTypes = ['product-image']
export const getUploadPresign = async (type: any, data: any) => {
    if (!uploadTypes.includes(type)) { throw new Error(`Invalid file type ${type}`) }
    const path = '/upload_presign/' + type
    const res = await ApiService.post(path, data)
    return res['data']
}

export const getAllProducts = async (page: any) => {
    const path = '/staff/product'
    return await ApiService.get(path, page)
}

export const addProduct = async (data: any) => {
    const path = '/staff/product'
    const res = await ApiService.post(path, data)
    return res['success']
}

export const editProduct = async (data: any, id: number | string) => {
    const path = '/staff/product/'+id
    const res = await ApiService.patch(path, data)
    return res['success']
}

export const getAllTyC = async () => {
    const path = '/staff/tyc';
    const res = await ApiService.get(path)
    return res['data']
}

export const addTyC = async (data: any) => {
    const path = '/staff/tyc'
    const res = await ApiService.post(path, data)
    return res['success']
}

export const editTyC = async(data: any, id: number | string)=>{
    const path = '/staff/tyc/'+id
    const res = await ApiService.patch(path, data)
    return  res['success']
}

export const getCatalogs = async () => {
    const path = '/staff/catalog'
    const res = await ApiService.get(path)
    return res['data']
}

export const getCity = async () => {
    const path = '/staff/city'
    const res = await ApiService.get(path)
    return res['data']
}

export const getCountries = async () => {
    const path = "/staff/countries"
    const res = await ApiService.get(path)
    return res['data']
}

export const addCatalog = async (data: any) => {
    const path = '/staff/catalog'
    const res = await ApiService.post(path, data)
    return res['success']
}

export const editCatalog = async (data: any, id: number | string) => {
    const path = '/staff/catalog/'+id
    const res = await ApiService.patch(path, data)
    return res['success']
}

export const getCategories = async () => {
    const path = '/staff/product_category';
    const res = await ApiService.get(path)
    return res['data']
}

export const addCategory = async (data: any) => {
    const path = '/staff/product_category';
    const res = await ApiService.post(path, data)
    return res['success']
}

export const editCategory = async (data: any, id: number | string) => {
    const path = `/staff/product_category/${id}`
    const res = await ApiService.patch(path, data)
    return res['success']
}

export const addCity = async (data: any) => {
    const path = '/staff/city'
    const res = await ApiService.post(path, data)
    return res['success']
}

export const editCity = async (data: any, id: number | string) => {
    const path = '/staff/city/'+id
    const res = await ApiService.patch(path, data)
    return res['success']
}

// We need to make sure there are no products associated with a category before deleting
export const deleteCategory = async (data: any, id: number | string) => {
    const path = `/staff/product_category/${id}`
    const res = await ApiService.delete(path, data)
    return res['success']
}

export const removeProductFromCatalog = async (data: any) => {
    const path = '/staff/catalog/product'
    const res = await ApiService.delete(path, data)
    return res['success']
}

export const removeProductFromCity = async (data: any) => {
    const path = '/staff/city/product'
    const res = await ApiService.delete(path, data)
    return res['success']
}

export const getOrders = async (page: any,items:number=10,status='open',keyword='', product_id:number) => {
    const path = '/staff/orders'
    return await ApiService.get(path, page, {items,status,keyword, product_id})
}

export const refundItemOrder = async (data: any) => {
    const path = '/order_item/refund'
    const res = await ApiService.post(path, data)
    return res['success']
}

export const setOrderStatus = async (data: any, id: number | string) => {
    const path = '/order_item/status/'+id
    const res = await ApiService.patch(path, data)
    return res['success']
}

export const searchUsers = async (data: any) => {
    const path = '/staff/user/search'
    const res = await ApiService.get(path, null, data)
    return res['data']
}

export const addAdminToOrg = async (data: any) => {
    const path = '/staff/org/admin'
    const res = await ApiService.post(path, data)
    return res['success']
}

export const removeAdminFromOrg = async (data: any) => {
    const path = '/staff/org/admin'
    const res = await ApiService.delete(path, data)
    return res['success']
}

export const activateStaffAccount = async (data: any) => {
    const path = "/staff/user/activate";
    const res = await ApiService.post(path, data);
    return res["success"];
};

export const getServices = async () => {
    const path = `/staff/available-services`
    const res = await ApiService.get(path)
    return res['data']
}

export const getOrgServices = async (id: number | string) => {
    const path = `/staff/services/${id}`
    const res = await ApiService.get(path)
    return res['data']
}

export const updateServices = async (data: any) => {
    const path = `/staff/services`
    const res = await ApiService.patch(path, data)
    return res['success']
}

export const updateOrgBalance = async (data: any) => {
    const path = `/staff/org/balanceUpdate`
    const res = await ApiService.post(path, data)
    return res['success']
}

export const sendcourtesy = async (data: any) => {
    const path = `/courtesy`
    const res = await ApiService.post(path, data)
    return res['success']
}

export const getForms = async () => {
    const path = `/forms`
    const res = await ApiService.get(path)
    return res['data']
}

export const getForm = async (id: number | string) => {
    const path = `/forms/${id}`
    const res = await ApiService.get(path)
    return res['data']
}

export const createForm = async (data: any) => {
    const path = `/forms/create`
    const res = await ApiService.post(path, data)
    return res['success']
}

export const archiveForm = async (data: any) => {
    const path = `/forms/archive`
    const res = await ApiService.post(path, data)
    return res['success']
}

export const updateForm = async (data: any) => {
    const path = `/forms/update`
    const res = await ApiService.post(path, data)
    return res['success']
}

export async function removeQuestion(data: any) {
    console.log('removeQuestion')
    const path = `/forms/delete`
    const res = await ApiService.post(path, data)
    return res['success']
}

export const getFormResponses = async (data:any) => {
    const path = `/forms/product/answers`
    const res = await ApiService.post(path,data)
    return res['data']
}

export const getAllFormQa = async () => {
    const path = `/forms/qa`
    const res = await ApiService.get(path)
    return res['data']
}

export const getAllOrders = async ( status:any ) => {
    const path = `/staff/total_orders`
    const res = await ApiService.get(path, status)
    return res['data']
}

////// Publicity //////

export const postPublicity = async (data:any) => {
    const path = `/publicity/create`
    const res = await ApiService.post(path,data)
    return res['data']
}

export const getPublicity = async () => {
    const path = `/publicity`
    const res = await ApiService.get(path)
    return res['data']
}

export const editPubli = async (data: any, id: number) => {
    const path = `/publicity/update/${id}`
    const res = await ApiService.patch(path, data)
    return res['success']
}

