import React, {useEffect, useState} from 'react';
import {
    Button, Chip,
    Dialog,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {createForm, getForm, removeQuestion, updateForm} from "../../../../service/api";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

interface Question {
    id?: number;
    type: string;
    question_anun: string;
    options?: string[];
}

interface FormProps {
    editable?: boolean;
    id?:number;
}


const CreateForm: React.FC<FormProps> = ({ editable, id }) => {
    const [name, setName] = useState( '')
    const [description, setDescription] = useState( '')
    const [questions, setQuestions] = useState<Question[]>( [] );
    const [open, setOpen] = useState<boolean>(false);
    const [option, setOption] = useState<string>('');
    const [newQuestion, setNewQuestion] = useState<Question>({
        id: 1,
        type: 'open',
        question_anun: '',
    })

    useEffect(() => {
        if ( id ){
            getForm(id).then((res : any) => {
                setName(res.name)
                console.log('ifff')
                setDescription(res.description)
                const questions = res.questions.map((question: any) => {
                    const str = question?.options[0]?.options ?? ''
                    console.log(str,res.questions)
                    return {
                        id: question.id,
                        type: question.type,
                        question_anun: question.question_anun,
                        options: str.substring(1, str.length - 1).split(",").map((item:any) => item.trim())
                    }
                })
                setQuestions(questions)
            })
        } else {
            console.log('nada')
        }
    },[id])

    const handleAddQuestion = () => {
        if (newQuestion.question_anun === '') return;
        const { id, ...questionWithoutId } = newQuestion;

        
        setNewQuestion({
            id: (newQuestion?.id ? newQuestion?.id : 0) + 1,
            type: 'text',
            question_anun: '',
        });
        
        setQuestions([...questions, questionWithoutId]);
    };

    const handleQuestionTypeChange = (event : any) => {
        const newType = event.target.value as string;
        setNewQuestion({
            ...newQuestion,
            type: newType,
            options: newType === 'select' ? [] : undefined,
        });
    };

    const handleOptionChange = (event: any) => {
        setOption(event.target.value);
    };

    const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewQuestion({
            ...newQuestion,
            question_anun: event.target.value,
        });
    };


    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const data = {
            title: name,
            formDescription: description,
            questions: questions,
        }
        if (!editable){
            console.log(data)
            await createForm(data)
        }else{
            console.log(data)
            await updateForm({form_id: id, ...data})
        }
        setOpen(false);
    };

    const close = () => {
        setOpen(false);
    }

    const handleAddOption = () => {
        setNewQuestion({
            ...newQuestion,
            options: [...(newQuestion.options ?? []), option],
        });
        setOption('');
    }

    const deleteQuestion = (id: number | undefined) => {
        if (!id) return;
        console.log('Click')
        removeQuestion({question_id: id})
        const newQuestions = questions.filter(question => question.id !== id)
        setQuestions(newQuestions)
    }


    return (
        <>
        <Button
            variant={ editable ? 'text' : 'contained'  }
            sx={!editable ?  {width:300}  : {minWidth: 50, p:0, m:0, color:'black'}}
            onClick={() => setOpen(true)}
        >
            {!editable ? "Crear Formulario" : "Editar"}
        </Button>

        <Dialog open={open} onClose={close} >
            <Stack sx={{height:'80vh', maxHeight:800, p:1}}>
                <Typography
                    variant={'h5'}
                    sx={{display:'flex',alignSelf:'center', fontWeight:'bold'}}
                >
                    {editable ? "Editar" : "Crear"} Formulario
                </Typography>

                <span style={{display:'flex', flexDirection:'row', marginBottom:1,marginTop:1}}>
                    <Typography
                        fontWeight={'bold'}
                        alignSelf={'baseline'}
                    >
                        Nombre del formulario:
                    </Typography>
                    <TextField
                        value={name}
                        onChange={(e:any)=>setName(e.target.value)}
                        variant={'standard'}
                        size={'small'}/>
                </span>

                <span style={{display:'flex', flexDirection:'row',marginBottom:2}}>
                    <Typography
                        fontWeight={'bold'}
                        alignSelf={'baseline'}
                    >
                        Descripcion:
                    </Typography>
                    <TextField
                        value={description}
                        onChange={(e:any)=>setDescription(e.target.value)}
                        variant={'standard'}
                        size={'small'}/>
                </span>

                <Paper
                elevation={2}
                sx={{p:2, flexDirection:'column', display:'flex', width:'100%', alignSelf:'center' }}>
                <InputLabel>Tipo de pregunta:</InputLabel>
                <FormControl size={'small'}>
                    <Select value={newQuestion.type}  onChange={handleQuestionTypeChange}>
                        <MenuItem value="text">Campo abierto</MenuItem>
                        <MenuItem value="select">Selección múltiple</MenuItem>
                    </Select>
                </FormControl>

                <InputLabel>Etiqueta de pregunta:</InputLabel>
                <TextField
                    size={'small'}
                    type="text"
                    value={newQuestion.question_anun}
                    onChange={handleLabelChange} />

                {newQuestion.type === 'select' && (
                    <div>
                        <InputLabel>Opciones:</InputLabel>
                        <Stack direction={'row'} flexWrap={'wrap'}>
                            <TextField
                                size={'small'}
                                type="text"
                                value={option}
                                onChange={(event) => handleOptionChange(event)} />
                            <div>
                                {newQuestion.options?.map((option) => (
                                    <Chip
                                        key={option}
                                        label={option}
                                        onDelete={() => {
                                            setNewQuestion({
                                                ...newQuestion,
                                                options: newQuestion.options?.filter(
                                                    (item) => item !== option
                                                ),
                                            });
                                    }}/>
                                ))}
                            </div>
                        </Stack>
                        <Button
                            onClick={handleAddOption}
                        >
                            Agregar opción
                        </Button>
                    </div>
                )}
                    <Stack direction={'row'} spacing={2} m={1}>
                        <Button
                            variant={'contained'}
                            sx={{width:200}}
                            onClick={handleAddQuestion}
                            disabled={newQuestion.type === 'open' || newQuestion.question_anun === ''} 
                        >
                            Agregar pregunta
                        </Button>
                        <Button
                            sx={{width:200}}
                            variant={'contained'}
                            onClick={handleSubmit}
                            disabled={questions.length === 0}
                        >
                            Guardar preguntas
                        </Button>
                    </Stack>
            </Paper>
            <Paper
                elevation={2}
                sx={{p:1, m:1,display:'flex', flexDirection:'row', width:'100%',flexWrap:'wrap',height:'100%'}}>
                <Typography width={'100%'} variant={"h4"}>Pre-visualizacion</Typography>

                {questions.map((question) => (
                    <Stack key={question.id} m={2} direction={'row'}>
                        <Stack direction={'column'}>
                            <InputLabel>{question.question_anun}</InputLabel>
                            {question.type === 'select' ? (
                                <FormControl size={'small'}>
                                    <Select>
                                        {question.options?.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <TextField variant={'standard'} size={'small'} type={question.type} />
                            )}
                        </Stack>
                        {question.id && <IconButton onClick={()=>deleteQuestion(question.id)}><DeleteForeverIcon/></IconButton>}
                    </Stack>
                ))}
            </Paper>
            </Stack>
        </Dialog>
        </>
    );
};

export default CreateForm
